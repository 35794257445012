<template>
  <div class="satMocks-score">
    <!-- <table class="inner-table" v-if="testTypeNew === 'composePractices'">
      <tbody>
        <tr>
          <td>
            <el-tooltip effect="dark" content="English" placement="top">
              <b>
                {{ escore }}
              </b>
            </el-tooltip>
            <b>
              <span
                class="asc-desc"
                style="color:#77ba41;"
                v-if="escore_up > 0"
              >
                <i class="fa fa-sort-up" aria-hidden="true"></i>{{ escore_up }}
              </span>
              <span class="asc-desc" style="color:#F00;" v-if="escore_up < 0">
                <i class="fa fa-sort-down" aria-hidden="true"></i
                >{{ -escore_up }}
              </span>
            </b>
          </td>
          <td class="M_score">
            <el-tooltip effect="dark" content="Math" placement="top">
              <div>
                <b>{{ mscore }}</b>
                <b>
                  <span
                    class="asc-desc"
                    style="color:#77ba41;"
                    v-if="mscore_up > 0"
                  >
                    <i class="fa fa-sort-up" aria-hidden="true"></i
                    >{{ mscore_up }}
                  </span>
                  <span
                    class="asc-desc"
                    style="color:#F00;"
                    v-if="mscore_up < 0"
                  >
                    <i class="fa fa-sort-down" aria-hidden="true"></i
                    >{{ -mscore_up }}
                  </span>
                </b>
              </div>
            </el-tooltip>
          </td>
        </tr>
        <tr>
          <td colspan="4" class="T_score">
            <span>
              <el-tooltip
                v-if="userExamId > 0"
                effect="dark"
                :content="title ? title : 'Total'"
                placement="top"
              >
                <b>
                  <a
                    :href="
                      `https://sat.ivy-way.com/result/${userExamId}?practiceType=composePractices&token=${token}`
                    "
                    target="_blank"
                  >
                    {{ tscore }}
                  </a>
                </b>
              </el-tooltip>
              <b v-else>
                {{ tscore }}
              </b>
            </span>
            <b>
              <span class="asc-desc" style="color:#77ba41;" v-if="score_up > 0">
                <i class="fa fa-sort-up" aria-hidden="true"></i>{{ score_up }}
              </span>
              <span class="asc-desc" style="color:#F00;" v-if="score_up < 0">
                <i class="fa fa-sort-down" aria-hidden="true"></i
                >{{ -score_up }}
              </span>
            </b>
            &nbsp;&nbsp;
            <span
              v-if="canEdit"
              @click="setExam"
              class="score-edit"
              style="color:#77ba41;margin-left:10px"
              ><i class="fa fa-edit"></i
            ></span>
            <span
              v-if="canEdit"
              @click="removeExam"
              class="score-edit"
              style="color:#F00;margin-left:15px"
              ><i class="far fa-trash-alt"></i
            ></span>
          </td>
        </tr>
      </tbody>
    </table> -->
    <el-tooltip effect="dark" content="English" placement="top" v-if="escore">
      <b>
        {{ escore }}
        +
      </b>
    </el-tooltip>
    <el-tooltip effect="dark" content="Math" placement="top" v-if="mscore">
      <b>
        {{ mscore }}
        =
      </b>
    </el-tooltip>
    <el-tooltip
      v-if="userExamId > 0"
      effect="dark"
      content="Total"
      placement="top"
    >
      <b>
        <a
          :href="
            `${satIvywayUrl}/result/${userExamId}?practiceType=${testTypeNew}&token=${token}`
          "
          target="_blank"
        >
          {{ tscore }} <span style="color: black;"></span>
        </a>
      </b>
    </el-tooltip>
    <b v-else>
      {{ tscore }} <span style="color: black;"></span>
    </b>

  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: [
    "rscore",
    "wscore",
    "escore",
    "mscore",
    "tscore",
    "rscore_up",
    "gscore_up",
    "escore_up",
    "mscore_up",
    "score_up",
    "canEdit",
    "userExamId",
    "classId",
    "testType",
    "question_count",
    "week",
    "title"
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    testTypeNew() {
      return this.getPracticeType(this.testType);
    },
    satIvywayUrl() {
      return process.env.VUE_APP_SAT_IVYWAY_FRONTEND_DOMAIN;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    getPracticeType(category) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;
      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      if (category >= 15) {
        return "composePractices";
      } else if (category == 11 || category == 12) {
        return "drill";
      } else if (category == 13 || category == 14) {
        return "singlePractice";
      } else {
        return "singlePractice";
      }
    },
    setExam() {
      this.$emit("setExam");
    },
    removeExam() {
      this.$emit("removeExam");
    }
  }
};
</script>

<style scoped>
.satMocks-score {
  font-size: 1.5rem
}
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.inner-table {
  text-align: center;
  width: 100%;
  height: 50%;
  border: 1px #333;
}
table.inner-table tr th,
table.inner-table tr td {
  border: none;
}
.table td .M_score {
  border-left: 1px solid #e6e9ed;
}
.table-bordered th,
.table-bordered td {
  border-left: none;
}
.table td .T_score {
  border-top: 1px solid #e6e9ed;
}
.T_score b {
  font-size: 18px;
}
.R_score,
.G_score,
.E_score,
.M_score,
.M_score b,
.T_score b {
  color: #444;
}
.inner-table b {
  font-size: 18px;
}
.inner-table .T_score b {
  font-size: 20px;
}
.T_score {
  cursor: pointer;
}
.score-edit {
  display: none;
}
.T_score:hover .score-edit {
  cursor: pointer;
  display: inline-block;
}
</style>
