<template>
  <div class="satMocks-score">
    <el-tooltip effect="dark" content="English" placement="top" v-if="escore">
      <b>
        {{ escore }}
        +
      </b>
    </el-tooltip>
    <el-tooltip effect="dark" content="Math" placement="top" v-if="mscore">
      <b>
        {{ mscore }}
        =
      </b>
    </el-tooltip>
    <el-tooltip
      v-if="userExamId > 0"
      effect="dark"
      content="Total"
      placement="top"
    >
      <b>
        <a
          :href="
            `${TOEFLIvywayUrl}/result?id=${userExamId}&IvywayToken=${TOEFLToken}`
          "
          target="_blank"
        >
          {{ tscore }} <span style="color: black;"></span>
        </a>
      </b>
    </el-tooltip>
    <b v-else> {{ tscore }} <span style="color: black;"></span> </b>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: [
    "tscore",
    "rscore_up",
    "gscore_up",
    "escore_up",
    "mscore_up",
    "score_up",
    "canEdit",
    "userExamId",
    "classId",
    "testType",
    "question_count",
    "week",
    "title",
    "TOEFLToken"
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    testTypeNew() {
      return this.getPracticeType(this.testType);
    },
    TOEFLIvywayUrl() {
      return process.env.VUE_APP_TOEFL_IVYWAY_FRONTEND_DOMAIN;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    getPracticeType(category) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;
      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;
      if (category >= 15) {
        return "composePractices";
      } else if (category == 11 || category == 12) {
        return "drill";
      } else if (category == 13 || category == 14) {
        return "singlePractice";
      } else {
        return "singlePractice";
      }
    },
    setExam() {
      this.$emit("setExam");
    },
    removeExam() {
      this.$emit("removeExam");
    }
  }
};
</script>

<style scoped>
.satMocks-score {
  font-size: 1.5rem;
}
.table td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.inner-table {
  text-align: center;
  width: 100%;
  height: 50%;
  border: 1px #333;
}
table.inner-table tr th,
table.inner-table tr td {
  border: none;
}
.table td .M_score {
  border-left: 1px solid #e6e9ed;
}
.table-bordered th,
.table-bordered td {
  border-left: none;
}
.table td .T_score {
  border-top: 1px solid #e6e9ed;
}
.T_score b {
  font-size: 18px;
}
.R_score,
.G_score,
.E_score,
.M_score,
.M_score b,
.T_score b {
  color: #444;
}
.inner-table b {
  font-size: 18px;
}
.inner-table .T_score b {
  font-size: 20px;
}
.T_score {
  cursor: pointer;
}
.score-edit {
  display: none;
}
.T_score:hover .score-edit {
  cursor: pointer;
  display: inline-block;
}
</style>
